<template>
  <base-section
    id="k-d-a-portfolio"
    class="secondary"
  >
    <v-carousel
      :height="strCarouselHeight"
      :cycle="m_bCycleCarousel"
      hide-delimiter-background
      show-arrows
    >
      <v-carousel-item
        v-for="(item, i) in m_arrProjects"
        :key="i"
      >
        <v-container

          class="align-center fill-height"
        >
          <k-d-a-portfolio-project-card
            :key="i"
            :obj-project="item"
            @showModal="showModal($event)"
          />

          <div
            v-if="false"
            class="py-3"
          />

          <k-d-a-portfolio-reviewer-card
            v-if="false"
            :obj-reviewer="item.objReviewer"
          />
        </v-container>

        <k-d-a-portfolio-modal
          :key="i"
          v-model="item.bShowDetails"
          :obj-project="item"
          @showModal="showModal($event)"
        />
      </v-carousel-item>
    </v-carousel>
  </base-section>
</template>

<script>
  import Projects from '@/mixins/projects'
  import TextClass from '@/mixins/text-class'

  export default {
    name: 'KDAPortfolio',

    components: {
      KDAPortfolioProjectCard: () => import('@/components/KDAPortfolioProjectCard'),
      KDAPortfolioReviewerCard: () => import('@/components/KDAPortfolioReviewerCard'),
      KDAPortfolioModal: () => import('@/components/KDAPortfolioModal'),
    },

    mixins: [Projects, TextClass],

    data: () => ({
      m_bCycleCarousel: true,
    }),

    computed: {
      m_arrProjects () {
        const projects = []
        this.g_arrProjects.forEach(item => {
          item.bShowDetails = false
          projects.push(item)
        })
        return projects
      },
      strCarouselHeight () {
        if (this.$vuetify.breakpoint.lgAndUp) {
          return '780'
        } else if (this.$vuetify.breakpoint.smAndUp) {
          return '800'
        } else {
          return '1300'
        }
      },
    },

    methods: {
      showModal (objShow) {
        this.m_arrProjects[objShow.key].bShowDetails = objShow.bShow
        this.m_bCycleCarousel = !objShow.bShow
      },
    },
  }
</script>
